var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"projects",attrs:{"id":"projects"}},[_c('h2',{staticClass:"projects__title"},[_vm._v(_vm._s(_vm.$t("projects.title")))]),_c('div',{staticClass:"projects__hover-content"},[_c('ul',{staticClass:"projects__list"},_vm._l((_vm.translatedProjects),function(item,index){return _c('li',{key:'item' + index,staticClass:"project",attrs:{"data-index":index},on:{"mouseover":_vm.projectHover,"mouseleave":function($event){return _vm.hideCircle(index)}}},[_c('div',{ref:`hover-circle--${index}`,refInFor:true,staticClass:"project__hover",on:{"click":function($event){return _vm.updateModal(item)}}},[_c('span',{staticClass:"project__hover--text"},[_vm._v(_vm._s(_vm.$t("projects.view")))])]),_c('div',{staticClass:"project__infos",on:{"click":function($event){return _vm.updateModal(item)}}},[_c('h3',{staticClass:"project__infos--title"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"project__infos--detail"},[_c('h4',{staticClass:"project__infos--style"},[_vm._v(_vm._s(item.style))]),_c('div',{staticClass:"project__techs"},_vm._l((item.techUse),function(tech){return _c('div',{key:tech.id,staticClass:"project__tech"},[_c('img',{staticClass:"project__tech--icon",attrs:{"src":require('../../assets/img/icons/techs/' + tech + '.svg'),"alt":tech}})])}),0)]),_c('div',{staticClass:"project__design__phone"},[_c('div',{staticClass:"project__background"},[_c('div',{staticClass:"project__presentation"},[_c('img',{staticClass:"project__presentation--img",attrs:{"src":require('../../assets/img/imgProjects/' +
                      item.alias +
                      '/presentation/' +
                      item.presentation),"alt":'presentation' + item.name}}),_c('span',{staticClass:"project__number"},[_vm._v(_vm._s(item.number))])])])]),_c('p',{staticClass:"project__description"},[_vm._v(_vm._s(item.description))]),_c('div',{staticClass:"project__action"},[_c('p',{staticClass:"project__action--text"},[_vm._v(_vm._s(_vm.$t("projects.explore")))]),_c('img',{staticClass:"project__action--arrow",attrs:{"src":require("../../assets/img/icons/projectArrow.svg"),"alt":"right arrow"}})])]),_c('div',{staticClass:"projects__design",on:{"click":function($event){return _vm.updateModal(item)}}},[_c('div',{staticClass:"project__background"},[_c('div',{staticClass:"project__presentation"},[_c('img',{staticClass:"project__presentation--img",attrs:{"src":require('../../assets/img/imgProjects/' +
                    item.alias +
                    '/presentation/' +
                    item.presentation),"alt":'presentation' + item.name}}),_c('span',{staticClass:"project__number"},[_vm._v(_vm._s(item.number))])])])])])}),0)]),_c('a',{directives:[{name:"magnetic",rawName:"v-magnetic",value:({ strength: 0.5, maxDistance: 40 }),expression:"{ strength: 0.5, maxDistance: 40 }"}],staticClass:"projects__button--more",attrs:{"target":"_blank","href":"https://github.com/Aubanyx?tab=repositories"}},[_c('div',{directives:[{name:"magnetic",rawName:"v-magnetic"}]},[_vm._v(_vm._s(_vm.$t("projects.more")))])]),_c('ProjectsModal')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }