var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"process",attrs:{"id":"processus"}},[_c('h2',{staticClass:"process__title"},[_vm._v(_vm._s(_vm.$t("process.title")))]),_c('p',{staticClass:"process__text"},[_vm._v(_vm._s(_vm.$t("process.intro")))]),_c('ul',{staticClass:"process__wrapper"},[_c('svg',{staticClass:"arrow",attrs:{"width":"4rem","height":"2rem"}},[_c('path',{attrs:{"d":"M0 0 L40 0 L20 20 Z","fill":"var(--color-secondary)"}})]),_vm._l((_vm.translatedProcess),function(step,index){return _c('li',{key:index,staticClass:"process__step"},[_c('svg',{staticClass:"process__step__lineY",attrs:{"width":_vm.windowWidth < 768 ? '1rem' : '1rem',"height":_vm.windowWidth < 768 && index === 3
            ? 'calc(100% + 4rem)'
            : _vm.windowWidth < 1280 && index === 3
            ? 'calc(100% + 4rem)'
            : _vm.windowWidth >= 1280
            ? '6rem'
            : '100%'}},[_c('line',{style:(_vm.windowWidth >= 768 &&
            (index === 0 || index === 2) &&
            _vm.windowWidth < 1280
              ? 'display: none'
              : 'stroke: var(--color-secondary); stroke-width: 2rem'),attrs:{"x1":"0","y1":_vm.windowWidth < 768 && index === 0
              ? '1.5rem'
              : _vm.windowWidth >= 768 &&
                (index === 0 || index === 1) &&
                _vm.windowWidth < 1280
              ? '1.5rem'
              : '0',"x2":"0","y2":"100%"}})]),_c('svg',{staticClass:"process__step__lineX",attrs:{"width":_vm.windowWidth < 768
            ? '100%'
            : _vm.windowWidth >= 768 &&
              (index === 0 || index === 2) &&
              _vm.windowWidth < 1280
            ? '160%'
            : _vm.windowWidth >= 1280 && index !== 3
            ? '150%'
            : '100%',"height":_vm.windowWidth < 768 ? '1rem' : '1rem'}},[_c('line',{staticStyle:{"stroke":"var(--color-secondary)","stroke-width":"2rem"},attrs:{"x1":_vm.windowWidth < 768 ? '0' : '3rem',"y1":"0","x2":_vm.windowWidth < 768 ? '4rem' : '100%',"y2":"0"}})]),_c('div',{staticClass:"process__step__content"},[_c('p',{staticClass:"process__step__content__number"},[_vm._v(_vm._s(step.number))])]),_c('p',{staticClass:"process__step__title"},[_vm._v(_vm._s(step.title))]),_c('p',{staticClass:"process__step__description"},[_vm._v(_vm._s(step.description))])])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }